import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import { LayoutProvider, Wrapper } from '../components/layout';
import { Blog } from '../components/sections';
import { IFileImage } from '../@types';
import { StackOnThemeT, stackStyled } from '../theme/StacksOnTheme';
import { useViewPortSize } from '../hooks';
import { ResponsiveContainer } from '../atoms/Containers';

const query = graphql`
  query {
    allStrapiBlogEntries {
      nodes {
        id
        mainArticle
        homeBtnText
        showInHome
        title
        blogEntries
        blogCategory {
          name
        }
        previewText
        slug
        bodyText
        strapiId
        mainMedia {
          alternativeText
          ext
          hash
          url
          formats {
            large {
              ext
              hash
              url
            }
            medium {
              ext
              hash
              url
            }
            small {
              ext
              hash
              url
            }
            thumbnail {
              ext
              hash
              url
            }
          }
        }
      }
    }
  }
`;

/* Strapi Data */
export interface IBlog {
  allStrapiBlogEntries: {
    nodes: Array<IAllBlogs>;
  };
}

export interface IAllBlogs {
  blogEntries?: any;
  homeBtnText?: string;
  showInHome?: boolean;
  title: string;
  previewText: string;
  slug: string;
  previewImage: IFileImage;
  mainArticle: boolean;
  mainMedia: IFileImage;
  blogCategory: {
    name: string;
    id?: string;
  };
  bodyText?: string;
}
/* End of Strapi Data  */
interface BlogComponentProps {
  isMobile?: boolean;
  $theme?: StackOnThemeT;
}

const MainWrapper = stackStyled(Wrapper, (props: BlogComponentProps) => ({
  display: 'flex',
  maxWidth: '1280px',
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'center',
  padding: props.isMobile ? '0 0' : '80px 0',
  margin: '0 auto',
  overflowX: 'hidden',
  textAlign: 'center',
  paddingBottom: '120px',
}));

const IndexPage: React.FC<PageProps> = (props: PageProps) => {
  const [isMobile, isTablet] = useViewPortSize();

  const data = useStaticQuery<IBlog>(query);

  return (
    <LayoutProvider seo={false} locationSearch={props.location.search} a>
      <MainWrapper isMobile={isMobile} noScroll>
        <ResponsiveContainer $style={{ alignItems: 'flex-start' }}>
          <Blog allStrapiBlogEntries={data.allStrapiBlogEntries} />
        </ResponsiveContainer>
      </MainWrapper>
    </LayoutProvider>
  );
};

export default IndexPage;
